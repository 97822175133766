// src/newsData.js
const newsData = [
    {
        id: 1,
        title: 'Syntext AI Launch',
        date: '2024-09-18',
        body: 'We are excited to announce the launch of our new <a href="https://www.syntextai.com/" target="_blank" rel="noopener noreferrer">SynTextAI</a>. Stay tuned for more details!'
    },
    // {
    //     id: 2,
    //     title: 'Upcoming Workshop',
    //     date: '2024-09-20',
    //     body: 'Join us for an intensive workshop on product development. Learn from experts and enhance your skills.'
    // },
    // {
    //     id: 3,
    //     title: 'Company Milestone',
    //     date: '2024-09-25',
    //     body: 'We have reached a significant milestone in our journey. Thank you for your continued support!'
    // }
];

export default newsData;
