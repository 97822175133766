// Example of a simple mobile-friendly navigation component
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './App.css';

const Navigation = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    return (
        <header className="header">
            <nav>
                <button className="nav-toggle" onClick={() => setIsNavOpen(!isNavOpen)}>
                    ☰
                </button>
                <ul className={`nav-menu ${isNavOpen ? 'open' : ''}`}>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/training">Training</Link></li>
                    <li><Link to="/events">Events</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Navigation;
