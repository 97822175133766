import React from 'react';

function Training() {
    return (
        <div className="training-container">
            <h1 className="training-title">4-Week Program to Build, Launch, and Sell Your Product</h1>
            <p className="training-intro">
                Are you eager to transform your idea into a market-ready product? In just 4 weeks, this intensive, hands-on course will equip you with the tools, knowledge, and practical guidance to build, deploy, and monetize your own product.
            </p>

            <section className="training-section">
                <h2>Course Highlights:</h2>
                <ul className="training-list">
                    <li>Frontend Development: Create stunning UIs with React.</li>
                    <li>Backend Development: Build secure backends using Python and integrate databases like SQLite and PostgreSQL.</li>
                    <li>Full-Stack Integration: Seamlessly connect frontend and backend.</li>
                    <li>AI Integration: Enhance your app with cutting-edge Gen AI models.</li>
                    <li>Authentication & Payments: Secure apps with JWT and integrate Stripe.</li>
                    <li>Cloud Deployment: Learn Docker and deploy on DigitalOcean, Azure, and AWS.</li>
                    <li>Marketing & Sales Strategy: Launch your product with a clear sales funnel.</li>
                </ul>
            </section>

            <section className="training-section">
                <h2>Program Dates:</h2>
                <p>4 Fridays | 4 Hours Each</p>
            </section>

            <section className="training-section">
                <h2>Price:</h2>
                <p>$1,500 USD or 0.015 mBTC</p>
            </section>

            <section className="training-section">
                <h2>Why Learn From Me?</h2>
                <p>
                    I’m a Product Engineer with over 14 years of experience bringing innovative products to market, including AI solutions used by millions. I’ve held leadership positions in leading AI startups and hold a US patent for satellite communication systems. Let me show you the entrepreneurial mindset and skills to help you succeed.
                </p>
            </section>

            <section className="training-section">
                <h2>By the End of the Program, You'll Have:</h2>
                <p>An MVP of your product and the confidence to turn ideas into real products.</p>
            </section>

            <section className="training-section">
                <h2>Who Should Join:</h2>
                <ul className="training-list">
                    <li>Aspiring Entrepreneurs looking to bring their ideas to life.</li>
                    <li>Developers & Freelancers wanting to build and launch products.</li>
                    <li>Consultants seeking to expand into product management and business growth.</li>
                </ul>
            </section>

            <section className="training-section">
                <h2>Requirements:</h2>
                <ul className="training-list">
                    <li>Basic programming knowledge (JavaScript/Python recommended)</li>
                    <li>Medium ability to troubleshoot with ChatGPT</li>
                    <li>Absolute eagerness to build and learn</li>
                </ul>
            </section>

            <section className="training-section">
                <h2>To Apply Now:</h2>
                <p>
                    Submit your resume/CV and an application letter describing the outcomes you want from this program. If you have a GitHub profile, include it in your application package and send it to <a href="mailto:hashmasked@protonmail.com" className="training-link">hashmasked@protonmail.com</a>
                </p>
                <a href="mailto:hashmasked@protonmail.com" className="btn-apply">Apply Now</a>
            </section>

            <section className="training-section">
                <h2>Get to Know Your Trainer:</h2>
                <ul className="training-list">
                    <li><a href="https://github.com/Sarsue" target="_blank" rel="noopener noreferrer" className="training-link">GitHub Profile</a></li>
                    <li><a href="https://www.linkedin.com/in/osasigbinedion/" target="_blank" rel="noopener noreferrer" className="training-link">LinkedIn Profile</a></li>
                </ul>
            </section>
        </div>
    );
}

export default Training;
