import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Bio = () => {
    return (
        <div className="bio-container">
            <h1>My Story</h1>

            <section className="bio-section">
                <h2>Introduction</h2>
                <p>
                    I’m Osas Emmanuel Igbinedion, a Product Engineer with 14 years of experience transforming visionary ideas into impactful products. My journey began with a prize-winning exam scheduling system built on the LAMP stack—Linux, Apache, MySQL, and PHP—which ignited my passion for creating robust web applications.
                </p>
            </section>

            <section className="bio-section">
                <h2>Professional Journey</h2>
                <p>
                    Since then, I’ve honed my skills in design, product management, full-stack development, and DevOps. Whether it’s crafting elegant frontends, building solid backends, or developing cutting-edge AI solutions, I excel at turning complex challenges into scalable, user-friendly solutions.
                </p>
            </section>
            <section className="bio-section">
                <h2>Notable Achievements</h2>
                <p>
                    I’m also the proud holder of a US Patent for{' '}
                    <a
                        href="https://patents.google.com/patent/CA3081732C/en"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bio-link"
                    >
                        situational awareness over low-bandwidth satellite systems
                    </a>, a notable achievement from my tenure at Nortac Defence.
                    At <a
                        href="https://aiac.ca/members/contextere/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bio-link"
                    >
                        Contextere
                    </a>, I spearheaded the development of Madison, an advanced AI platform designed to provide real-time insights and guidance to industrial workers. Madison enhances situational awareness and reduces cognitive overload by delivering actionable insights in high-pressure environments, significantly improving productivity and addressing skills gaps, particularly in aviation maintenance.
                </p>
            </section>


            <section className="bio-section">
                <h2>Current Projects</h2>
                <p>
                    Currently, I’m dedicated to building{' '}
                    <a
                        href="https://www.syntextai.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bio-link"
                    >
                        SynTextAI
                    </a>, an AI-powered tool designed to revolutionize document processing and insights. Additionally, I’m diving into the world of decentralized finance by running Bitcoin Lightning nodes, facilitating microtransactions and cross-border payments.
                </p>
            </section>

            <section className="bio-section">
                <h2>Get in Touch</h2>
                <p>
                    Ready to bring your ideas to life? My{' '}
                    <Link to="/training" className="bio-link">
                        4-Week Program: Build, Launch, and Sell Your Product
                    </Link>{' '}
                    provides expert guidance to help you turn concepts into market-ready successes.
                </p>
            </section>
        </div>
    );
};

export default Bio;
