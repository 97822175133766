// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Bio from './Bio';
import Training from './Training';
import Events from './Events'; // Import the Events component
import Navigation from './Navigation'; // Import the Navigation component
import './App.css'; // Import the CSS file for styling

function App() {
  return (
    <Router>
      <div>
        <header className="header">
          <h1 className="company-name">Osas Inc</h1>
          <p className="tagline">Innovative Solutions for Tomorrow</p>
          <p className="description">Turning your ideas into reality with cutting-edge technology.</p>
        </header>
        <Navigation /> {/* Use the Navigation component */}
        <main>
          <Routes>
            <Route path="/" element={<Bio />} />
            <Route path="/training" element={<Training />} />
            <Route path="/events" element={<Events />} /> {/* Route for Events component */}
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
